import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "map", "latitude", "longitude", "street", "city", "state", "zip", "country" ]

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(this.data.get("latitude") || 39.5, this.data.get("longitude") || -98.35),
      zoom: (this.data.get("latitude") == null ? 4 : 15)
    })

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    })
  }

  placeChanged() {
    let place = this.autocomplete.getPlace()

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`)
      return
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
      this.map.setZoom(17)
    }

    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()

    let addressComponents = {
      streetNumber: null,
      route: null,
      city: null,
      state: null,
      country: null,
      zip: null,
    }
    
    place.address_components.forEach(value => { 
      if (value.types.includes('street_number')) { 
        addressComponents.streetNumber = value.long_name
      }

      if (value.types.includes('route')) {
        addressComponents.route = value.long_name
      }

      if (value.types.includes('locality')) {
        addressComponents.city = value.long_name
      }

      if (value.types.includes('administrative_area_level_1')) {
        addressComponents.state = value.short_name
      }

      if (value.types.includes('country')) {
        addressComponents.country = value.short_name
      }

      if (value.types.includes('postal_code')) {
        addressComponents.zip = value.short_name
      }
    })

    this.streetTarget.value = addressComponents.streetNumber + " " + addressComponents.route
    this.cityTarget.value = addressComponents.city
    this.stateTarget.value = addressComponents.state
    this.zipTarget.value = addressComponents.zip
    this.countryTarget.value = addressComponents.country
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}